import React from "react";
import { EditGuesser, InputGuesser, } from "@api-platform/admin";
import { ReferenceInput, SelectArrayInput, SelectInput, AutocompleteInput } from 'react-admin';

const ShopsEdit = props => {
    const transform = function (args) {
        return {
            "@id": args['@id'],
            id: args.id,
            name: args.name,
            category: args.category,
            phoneNumber: args.phoneNumber,
            validated: args.validated,
            payments: args.payments,
            quota: args.quota,
            quotaExtra: args.quotaExtra,
            discountTypes: args.discountTypes,
            subscriptionOfferType: args.subscriptionOfferType,
            subscriptionType: args.subscriptionType,
            subscriptionAmount: args.subscriptionAmount
        };
    }

    return (
        <EditGuesser {...props} transform={transform}>
            <InputGuesser source="name"/>
            <InputGuesser source="phoneNumber"/>
            <InputGuesser source="validated"/>
            <ReferenceInput label="Category" source="category" reference="categories">
                <AutocompleteInput optionText="name" />
            </ReferenceInput>
            <SelectArrayInput source="payments" choices={[
                { id: 'cb', name: 'Carte Bleue' },
                { id: 'lydia', name: 'Lydia' },
                { id: 'paypal', name: 'Paypal' },
                { id: 'apple_pay', name: 'Apple Pay' },
                { id: 'google_pay', name: 'Google Pay' },
                { id: 'ticket_restaurant', name: 'Tickets Restaurant' },
            ]} />
            <InputGuesser label="Quota récompenses mensuelles" source="quota" />
            <InputGuesser label="Quota récompenses suppl." source="quotaExtra" />
            <SelectArrayInput label="Récompenses accessibles" source="discountTypes" choices={[
                { id: 'code', name: 'Code promo' },
                { id: 'percent', name: 'Réduction' },
                { id: 'voucher', name: "Bon d'achat" },
                { id: 'other', name: 'Autre' },
            ]} />
            <SelectInput label="Type d'offre d'abonnement" source="subscriptionOfferType" choices={[
                { id: 'basic_collect', name: 'Basic - Collect' },
                { id: 'basic_loyalty', name: 'Basic - Loyalty' },
                { id: 'basic_all', name: 'Basic - Collect / Loyalty' },

                { id: 'pro_collect', name: 'Pro - Collect' },
                { id: 'pro_loyalty', name: 'Pro - Loyalty' },
                { id: 'pro_all', name: 'Pro - Collect / Loyalty' },

                { id: 'advanced_collect', name: 'Advanced - Collect' },
                { id: 'advanced_loyalty', name: 'Advanced - Loyalty' },
                { id: 'advanced_all', name: 'Advanced - Collect / Loyalty' },

                { id: 'enterprise_collect', name: 'Enterprise - Collect' },
                { id: 'enterprise_loyalty', name: 'Enterprise - Loyalty' },
                { id: 'enterprise_all', name: 'Enterprise - Collect / Loyalty' },
            ]} />
            <SelectInput label="Type d'abonnement" source="subscriptionType" choices={[
                { id: 'monthly', name: 'Mensuel' },
                { id: 'annually', name: 'Annuel' },
            ]} emptyValue={null} />
            <InputGuesser label="Montant de l'abonnement" source="subscriptionAmount" />

            {/* INSTAGRAM */}
            <InputGuesser label="Instagram Id (OLD)" source="instagramBusinessAccountId" />
            <InputGuesser label="Instagram Username (OLD)" source="instagramBusinessAccountName" />
        </EditGuesser>
    );
}

export default ShopsEdit
