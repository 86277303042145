import React from "react";
import {
    EditGuesser,
    InputGuesser,
} from "@api-platform/admin";

import {SelectArrayInput} from 'react-admin';

const UsersEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name"/>
        <InputGuesser source="email"/>
        <InputGuesser source="activated"/>
        <SelectArrayInput source="roles"  choices={[
            { id: 'ROLE_ADMIN', name: 'Admininstrator' },
            { id: 'ROLE_OWNER', name: 'Shop' },
            { id: 'ROLE_USER',  name: 'User' },
        ]} />

        {/* INSTAGRAM */}
        <InputGuesser label="Instagram Id (OLD)" source="instagramId" />
        <InputGuesser label="Instagram Username (OLD)" source="instagramUsername" />
    </EditGuesser>
);

export {UsersEdit}
