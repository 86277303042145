import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";
import {
    TabbedShowLayout,
    Tab,
    ReferenceManyField,
    Pagination,
    Datagrid,
    TextField,
    NumberField,
    ReferenceField
} from 'react-admin';

const UsersShow = props => (
    <ShowGuesser {...props}>
        <TabbedShowLayout>
            <Tab label={"Summary"}>
                <FieldGuesser source="name" addLabel={true}/>
                <FieldGuesser source="email" addLabel={true}/>
                <FieldGuesser source="roles" addLabel={true}/>
                <FieldGuesser source="activated" addLabel={true}/>
                <FieldGuesser source="tagsCount" addLabel={true}/>
                <FieldGuesser source="likesCount" addLabel={true}/>
                <FieldGuesser source="fansCount" addLabel={true}/>
                <ReferenceField source="shop" reference="shops" linkType={"show"} label={"Shop"} addLabel={true}>
                    <TextField source="name"/>
                </ReferenceField>
            </Tab>
            <Tab label={"Discounts"}>
                <ReferenceManyField pagination={<Pagination/>} reference="discounts" target="owner" label="Discounts">
                    <Datagrid>
                        <NumberField source="amount_in_percent"/>
                        <ReferenceField source="product" reference="products" linkType={"show"}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <ReferenceField source="shop" reference="shops" linkType={"show"}>
                            <TextField source="name"/>
                        </ReferenceField>
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
            <Tab label={"SocialAccounts"}>
                <ReferenceManyField pagination={<Pagination/>} reference="social_accounts" target="user" label="SocialAccounts">
                    <Datagrid>
                        <TextField source="social" />
                        <TextField source="account_id" label="Account ID" />
                        <TextField source="account_name" label="Account Name" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </ShowGuesser>
);

export default UsersShow
