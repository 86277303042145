import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";
import {
    TabbedShowLayout,
    Tab,
    ReferenceManyField,
    Pagination,
    Datagrid,
    TextField,
    FunctionField
} from 'react-admin';


const ShopsShow = props => (
    <ShowGuesser {...props}>
        <TabbedShowLayout>
            <Tab label={"Summary"}>
                <FieldGuesser source="name" addLabel={true} />
                <FieldGuesser source="phoneNumber" addLabel={true} />
                <FieldGuesser source="validated" addLabel={true} />
                <FieldGuesser source="validatedAt" addLabel={true} />
                <FieldGuesser source="createdAt" addLabel={true} />
                <FunctionField
                    source="instagramBusinessAccountName"
                    label="Page Instagram"
                    render={record => {
                        return record.instagramBusinessAccountName ? (
                            <a href={`https://instagram.com/${record.instagramBusinessAccountName}`} target="_blank" rel="noopener noreferrer">
                                {record.instagramBusinessAccountName}
                            </a>
                        ) : ''
                    }}
                />
            </Tab>
            <Tab label={"SocialAccounts"}>
                <ReferenceManyField pagination={<Pagination/>} reference="social_accounts" target="shop" label="SocialAccounts">
                    <Datagrid>
                        <TextField source="social" />
                        <TextField source="account_id" label="Account ID" />
                        <TextField source="account_name" label="Account Name" />
                    </Datagrid>
                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </ShowGuesser>
);

export default ShopsShow
