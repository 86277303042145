import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";
import {Filter, ReferenceField, TextField, TextInput, BooleanInput} from "react-admin";
import Avatar from "@material-ui/core/Avatar";

const ShopFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name"/>
        <BooleanInput label="Validated" source="validated"/>
    </Filter>
);

const ShopAvatar = ({record}) => {
    if (record === null) {
        return <Avatar variant="rounded"/>;
    }

    if (record.image !== undefined && record.image !== null) {
        return <Avatar src={record.image.uri} variant="rounded"/>
    }

    if (record.name !== null) {
        return <Avatar alt={record.name} variant="rounded">{record.name}</Avatar>
    }

    return <Avatar variant="rounded"/>;
};

const ShopsList = props => (
    <ListGuesser {...props} filters={<ShopFilter/>}>
        <ShopAvatar label={'Picture'}/>
        <FieldGuesser source="name" />
        <FieldGuesser source="phoneNumber" />
        <FieldGuesser source="validated" />
        <FieldGuesser source="createdAt" />
        <ReferenceField label="Category" source="category" reference="categories">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Owner" source="owner" reference="users">
            <TextField source="name" />
        </ReferenceField>
        <FieldGuesser label="Instagram Username (Old)" source="instagramBusinessAccountName"/>
    </ListGuesser>
);

export default ShopsList;
